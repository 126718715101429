import moment from 'moment';
import axios from '@axios';
import { Distributor } from "@models/distributor";
import { SelectedMoeda } from "@models/order/moedas/response";
import { TypeResponse, TypeResponseApi } from "@models/order/type/response";
import { TransferClient } from "@models/transfer-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from '@redux/reducers';
import { PaginatedResponseApi } from '@models/order/items/response';
import { translate } from '@components/i18n';
import { TransferOrder } from '@models/transfer-order';
import { ThunkCallback } from '../model/thunk-callback';
import { addNotification, Notification } from '../notification';
import { OrderItem } from './order-item';
import { fetchMoneyTypes } from '../money-type';
import { fetchDistributor } from '../distributor';
import { fetchTransferClients } from '../transfer-clients';

export const fetchTransferAvailableItems = createAsyncThunk<OrderItem.Response[], void>('transfer-order/available-items', async (_, thunkAPI) => {
    try {
        const { transferOrder } = thunkAPI.getState() as RootState;

        if(!transferOrder) throw new Error("Não encontrado o Transfer Order")

        const params = {
          codigoCliente: transferOrder?.client.id,
          codigoFilial: transferOrder?.filial?.id,
          moeda: transferOrder?.moneyType?.codigo,
          page: 0,
          size: 1000,
      }

        console.debug(params);

        const response = await axios.get<OrderItem.Response[]>('/itemtransfer/byusuario', { params });
       // /byusuario
        const { data: dataReponse } = response;

        if(!dataReponse){
          throw new Error(translate('general.erroListingItems'))
        }

        let items = dataReponse?.map((item: OrderItem.Response) =>
            Object.assign(new OrderItem.ResposeApi(), item).fromJSON()
        ) || []

        // exist transfer order
        if(!!transferOrder.order){
          const orderItems = transferOrder.order.pedidoItens
          items = items.map(item => {
            const finded = orderItems.find(iten => iten.numeroItemTransfer === item.numero)
            if(finded){
              return ({
                ...item,
                quantity: finded.quantidade,
                preco: finded.precoUnitario,
                totalValue: finded.precoTotal,
              })
            }
            return item
          })
        }

        console.debug({ params, items }, 'loaded items', params);

        return items;
    } catch (e) {
      let message = ''

      if(e.message){
        message = e.message
      }

      return thunkAPI.rejectWithValue(message);
    }
});

export const mutationTransferOrder = createAsyncThunk<OrderItem.Response[], ThunkCallback<OrderItem.Request>>('transfer-order/create', async (req, thunkAPI) => {
  try {
    const { transferOrder } = thunkAPI.getState() as RootState;

    if(!transferOrder) throw new Error("Não encontrado o Transfer Order")

    const selectedItems = transferOrder.availableItems.filter(item => item.quantity > 0);
    const valorTotal = selectedItems.reduce((prev, current) => prev + current.totalValue, 0)

    const items = selectedItems.map(item => ({
      descricaoItem: item.descricao,
      multiplo: item.multiplo,
      numeroItem: item.numero,
      precoTotal: item.totalValue,
      precoUnitario: item.preco,
      quantidade: item.quantity,
      quantidadeTotal: item.totalQuantity,
    }))
    
    const ref = transferOrder.ref

    const request = {
      codigoFilial: transferOrder.filial.id,
      comentario: req.data.note,
      dataPedido: moment(new Date()).format('YYYY-MM-DD'),
      emailCopia: req.data.email,
      idCliente: (!!ref) ? transferOrder.order.idCliente : transferOrder.client.id,
      itens: items,
      status: req.data.isDraft ? 'RASCUNHO' : 'NAO_FATURADO',
      valorTotal,
      codigoMoeda: transferOrder.moneyType.codigo,
      codigoDistribuidor: (!!ref) ? transferOrder.order.codigoDistribuidor : transferOrder.distributor.codigo,
    }

   
    
    if(!!ref){ // to update
      const { status } = await axios.put<PaginatedResponseApi<OrderItem.Response>>(`/pedidotransfer/${ref}`, request);
      
      if(status === 200){
        thunkAPI.dispatch(
          addNotification({
              type: 'success',
              message: req.data.isDraft ? translate('general.orderUpdatedAndDraft')  : translate('general.orderUpdated'),
              title: translate('general.success'),
              notificationKey: req.notificationKey,
              callback: () => req.onSuccess(),
          })
        );
      }

      return
    }

    const { status } = await axios.post<PaginatedResponseApi<OrderItem.Response>>('/pedidotransfer', request);
      
    if(status === 201){
      thunkAPI.dispatch(
        addNotification({
            type: 'success',
            message: req.data.isDraft ? translate('general.orderCreatedAndDraft')  : translate('general.orderSended'),
            title: translate('general.success'),
            notificationKey: req.notificationKey,
            callback: () => req.onSuccess(),
        })
      );
    }

  } catch (e) {
    console.error(e);

    let message = ''
    if(e?.response?.data && e.response.data.length > 0 && e.response.data[0].msgUsuario){
        message = e.response.data[0].msgUsuario;
    }else{
        message = translate('general.error');
    }
    
    thunkAPI.dispatch(
        addNotification({
            type: 'error',
            message: message,
            title: translate('general.errorT'),
            notificationKey: req.notificationKey,
        }),
    );
    return thunkAPI.rejectWithValue(message);
  }
})

export const fetchTransferOrder = createAsyncThunk<TransferOrder.Response, string>('transfer-order/get', async (ref, thunkAPI) => {
  try {
    const { transferOrder, moneyType } = thunkAPI.getState() as RootState;

    if(!transferOrder) throw new Error("Não encontrado o Transfer Order")

    const { data } = await axios.get<TransferOrder.Response>(`/pedidotransfer/${ref}`);

    const response = Object.assign(new TransferOrder.ResposeApi(), data).fromJSON()

    thunkAPI.dispatch(updateTransferRef({ ref: response.id.toString() }))

    // load distributor
    thunkAPI.dispatch(fetchDistributor(response.codigoDistribuidor)).unwrap().then(res => {
      if(res){
        thunkAPI.dispatch(updateTransferDistributor({ distributor: res }))
      }
    })

    // load client transfer
    thunkAPI.dispatch(fetchTransferClients({
      page: 0,
      size: 1,
      filterType: 'CNPJ',
      filterString: response.cnpjCliente.toString(),
    })).unwrap().then(res => {
      if(res?.content?.length > 0){
        thunkAPI.dispatch(updateTransferClient({ client: res.content[0] }))
      }
    })

    // load money type
    let moneySelected;
    if(moneyType.isSuccess){
      moneySelected = moneyType?.available?.find(item => item.codigo === response.codigoMoeda)
    }else{
      const moneyTypes = await thunkAPI.dispatch(fetchMoneyTypes()).unwrap()
      moneySelected = moneyTypes?.find(item => item.codigo === response.codigoMoeda)
    }

    if(moneySelected){
      thunkAPI.dispatch(updateTransferMoneyType({ moneyType: moneySelected}))
    }

    return response
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
})

export const deleteTransferOrder = createAsyncThunk<void, ThunkCallback<string>>('transfer-order/delete', async (req, thunkAPI) => {
  try {
    const res = await axios.delete<void>(`/pedidotransfer/${req.data}`);

    if(res.status === 200){
      thunkAPI.dispatch(
        addNotification({
            type: 'success',
            message: translate('general.orderDeleted') ,
            title: translate('general.success'),
            notificationKey: req.notificationKey,
            callback: () => req.onSuccess(),
        })
      );
    }

  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
})

export const copyTransferOrder = createAsyncThunk<TransferOrder.Response, ThunkCallback<number>>('transfer-order/copy', async (req, thunkAPI) => {
  try {
    const { transferOrder } = thunkAPI.getState() as RootState;

    if(!transferOrder || !transferOrder.order || !req.data) {
      throw new Error("Não encontrado o Transfer Order")
    }

    const { data } = await axios.get(`/pedidotransfer/${req.data}`);

    const responseApi = Object.assign(new TransferOrder.ResposeApi(), data)

    const items = responseApi.pedidoItens.map(item => ({
      descricaoItem: item.descricaoItemTransfer,
      multiplo: item.multiplo,
      numeroItem: item.numeroItemTransfer,
      precoTotal: item.precoTotal,
      precoUnitario: item.precoUnitario,
      quantidade: item.quantidade,
      quantidadeTotal: item.quantidade * item.multiplo,
    }))
    
    const request = {
      codigoFilial: responseApi.codigoFilial,
      comentario: responseApi.comentario,
      dataPedido: moment(new Date()).format('YYYY-MM-DD'),
      emailCopia: responseApi.email,
      idCliente: responseApi.idCliente,
      itens: items,
      status: "RASCUNHO",
      valorTotal: responseApi.valorTotal,
      codigoMoeda: responseApi.codigoMoeda,
      codigoDistribuidor: responseApi.codigoDistribuidor,
    }

    const response = await axios.post('/pedidotransfer', request);

    if (response.status === 201) {
      thunkAPI.dispatch(
        addNotification({
            type: 'success',
            message: translate('general.orderCreatedAndDraft'),
            title: translate('general.success'),
            notificationKey: req.notificationKey,
            callback: () => req.onSuccess(response.data.pedidoId),
        }),
      );
    }
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
})

export const selecTransferClient = createAsyncThunk<void, ThunkCallback<TransferClient.Response>>('transfer-client/select', async (req, thunkAPI) => {
  try {
    thunkAPI.dispatch(updateTransferClient({client: req.data}))

    let notificationData: Notification = {
      type: 'error',
      notificationKey: req.notificationKey,
      message: translate('general.distributorNotFound'),
      title: translate('general.error'),
    }

    // load distributor
    if(req.data.codigoDistribuidor){
      thunkAPI.dispatch(fetchDistributor(req.data.codigoDistribuidor)).unwrap().then(res => {
        if(res){
          thunkAPI.dispatch(updateTransferDistributor({ distributor: res }))
          thunkAPI.dispatch(addNotification({
            type: 'success',
            notificationKey: req.notificationKey,
            message: translate('general.distributorloaded'),
            title: translate('general.success'),
            callback: req.onSuccess
          }));
        }else{
          thunkAPI.dispatch(addNotification({
            ...notificationData,
            message: translate('general.distributorNotFound'),
          }));
        }
      })
    }else{
      thunkAPI.dispatch(addNotification({
        ...notificationData,
        message: translate('general.clientHasNotDistributor'),
      }));
    }
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
})

export type ITransferItemsFilter = {
  filterType: string
  filterString: string
}

export interface ITransferOrder {
  distributor: Distributor.Response
  client: TransferClient.Response
  submitting: boolean
  loading: boolean
  filial: TypeResponse
  moneyType: SelectedMoeda
  ref?: string
  order?: TransferOrder.Response,

  // items
  availableItems: OrderItem.Response[]
  isFetchingItems: boolean
  isSuccessItems: boolean
  isErrorItems: boolean

  filterItems: ITransferItemsFilter
}

const initialState: ITransferOrder = {
  distributor: undefined,
  client: undefined,
  submitting: false,
  filial: undefined,
  moneyType: undefined,
  loading: false,

  availableItems: [],
  isFetchingItems: false,
  isSuccessItems: false,
  isErrorItems: false,
  filterItems: {
    filterType: 'Todos',
    filterString: ''
  }
};

const transferOrderSlice = createSlice({
  name: 'transfer-order-slice',
  initialState,
  reducers: {
    clearTransferOrder: (state) => {
      state = {...initialState}
      return state
    },
    updateTransferBranch: (state, action) => {
      state.filial = action.payload.filial
      return state;
    },
    updateTransferDistributor: (state, action) => {
      state.distributor = action.payload.distributor
      return state;
    },
    updateTransferClient: (state, action) => {
      state.client = action.payload.client
      return state;
    },
    updateTransferMoneyType: (state, action) => {
      state.moneyType = action.payload.moneyType
      return state;
    },
    updateTransferRef: (state, action) => {
      state.ref = action.payload.ref
      return state;
    },
    updateTransferFilter: (state, action) => {
      const filter = {...state.filterItems}
      Object.assign(filter, action.payload)
      state.filterItems = filter
      return state;
    },
    updateOrderTransferItem:(state, action) => {
      const index = state.availableItems.findIndex((item) => item.id === action.payload.id)
      if(index > -1) {
        const item = state.availableItems[index]

        const totalQuantity = (item.multiplo * action.payload.quantity || 0)

        state.availableItems[index] = {
          ...item,
          ...action.payload.item,
          quantity: action.payload.quantity,
          totalQuantity: totalQuantity,
          totalValue: (item.preco * totalQuantity || 0),
        }
      }
      return state;
    },
  },
  extraReducers: {
    [fetchTransferAvailableItems.pending.toString()]: (state) => {
        state.isSuccessItems = false;
        state.isFetchingItems = true;
        return state;
    },
    [fetchTransferAvailableItems.rejected.toString()]: (state) => {
      state.isSuccessItems = false;
      state.isFetchingItems = false;
      state.isErrorItems = true;
      return state;
    },
    [fetchTransferAvailableItems.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<OrderItem.Response[]>,
    ) => {
        state.availableItems = payload;
        state.isSuccessItems = true;
        state.isFetchingItems = false;
        return state;
    },
    [fetchTransferOrder.pending.toString()]: (state) => {
      state.loading = true;
      return state;
    },
    [fetchTransferOrder.rejected.toString()]: (state) => {
      state.loading = false;
      return state;
    },
    [fetchTransferOrder.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<TransferOrder.Response>,
    ) => {
        state.order = payload;
        state.loading = false;

        state.client = Object.assign(new TransferClient.Response(), {
          id: payload.idCliente,
          cidade: payload.cidadeCliente,
          cnpj: payload.cnpjCliente,
          codigoDistribuidor: payload.codigoDistribuidor,
          nome: payload.razaoSocial,
          zona: payload.zonaCliente,
        })

        state.filial = Object.assign(new TypeResponseApi(), {
          codigo: payload.codigoFilial,
          descricao: payload.descricaoFilial,
          convenio100: "",
        }).toTypeResponse()

        return state;
    },
  },
})

//@ts-ignore
export default transferOrderSlice.reducer;

export const { 
  updateTransferClient,
  updateTransferDistributor,
  updateTransferBranch, 
  clearTransferOrder,
  updateTransferMoneyType,
  updateTransferRef,
  updateTransferFilter,
  updateOrderTransferItem,
} = transferOrderSlice.actions;

export const transferOrderSelector = (state): ITransferOrder => state.transferOrder;

export const transferOrderMoneySymbolSelector = (state): string => {
  const moneySymbols = state?.moneyType?.availableSymbols || []
  const code = state?.transferOrder?.moneyType?.codigo

  if(!code) return ''

  if(moneySymbols.length === 0) return ''

  const symbol = moneySymbols.find(s => s.codigoMoeda === code)

  if(!symbol) return ''

  return symbol.simbolo
}