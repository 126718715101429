import moment from 'moment';
import { translate } from '@components/i18n';
import { formatMoney } from '@utils/money';
import { useSelector } from 'react-redux';
import { moneySymbolsSelector } from '@redux/slices/money-type';

export const historyColumns = [
    {
        title: translate('columns.order'),
        dataIndex: 'id',
        sorter: false,
        width: '6%',
    },
    {
        title: translate('columns.saleOrder'),
        dataIndex: 'pedidoVendaId',
        sorter: false,
        width: '6%',
    },
    {
        title: translate('columns.clientTransfer'),
        dataIndex: 'razaoSocial',
        width: '25%',
    },
    {
        title: translate('columns.transferClientCity'),
        dataIndex: 'cidadeCliente',
        width: '10%',
    },
    {
        title: translate('columns.startDate'),
        dataIndex: 'dataPedido',
        sorter: false,
        width: '10%',
        render: (_, record): React.ReactNode => {
            return (
                <span>
                    {moment(record.dataPedido).format('DD/MM/YYYY')}
                </span>
            );
        },
    },
    {
        title: translate('columns.distributor'),
        dataIndex: 'nomeDistribuidor',
        width: '15%',
        render: (_, record) => {

            return (
                <span>
                    {`${record?.codigoDistribuidor} - ${record?.nomeDistribuidor}`}
                </span>
            )
        }
    },
    {
        title: translate('columns.branch'),
        dataIndex: 'descricaoFilial',
        width: '15%',
        render: (_, record) => {

            return (
                <span>
                    {`${record?.codigoFilial} - ${record?.descricaoFilial}`}
                </span>
            )
        }
    },
    {
        title: translate('columns.status'),
        dataIndex: 'status',
        sorter: false,
        width: '12%',
        render: (_, record) => {
            const status = filterTypesStatus?.find(item => item.name === record.status)

            return (
                <span>
                    {status?.description}
                </span>
            )
        }
    },
    {
        title: translate('columns.total'),
        dataIndex: 'total',
        sorter: false,
        width: '20%',
        render: (_, record): JSX.Element => {
            const total = record?.pedidoItens?.reduce((prev, current) => prev + current.precoTotal, 0)
            const moneyCode = record.codigoMoeda
            const symbols = useSelector(moneySymbolsSelector)
            const symbol = symbols.find(item => item.codigoMoeda === moneyCode)

            return (
                <span>{formatMoney(symbol?.simbolo, total || 0)}</span>
            )
        }
    },
];

export const filterTypes = [
    {
        name: 'PEDIDO',
        description: translate('filters.order'),
    },
    {
        name: 'PEDIDO_VENDA',
        description: translate('filters.saleOrder'),
    },
    {
        name: 'CNPJ_CLIENTE',
        description: translate('filters.cnpj'),
    },
    {
        name: 'NOME_CLIENTE',
        description: translate('filters.client'),
    },
    {
        name: 'CIDADE_CLIENTE',
        description: translate('filters.city'),
    },
    {
        name: 'DISTRIBUIDOR',
        description: translate('filters.distributorCode'),
    },
    {
        name: 'NOME_DISTRIBUIDOR',
        description: translate('filters.distributorName'),
    },
];

export const filterTypesStatus = [
    {
        name: 'NAO_FATURADO',
        description: translate('general.noBilled'),
    },
    {
        name: 'FATURADO',
        description: translate('general.billed'),
    },
];
